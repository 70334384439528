// types
import type {
  ProductProduct,
  ProductCategory,
  CategoryItem,
} from 'components/Product/reducer.types';

// utils
import { isNotEmpty } from '../is-not-empty';
import currentLanguageSelector from './languageSelectors';

// Get the current catalog language
export const currentCatalogSelector = (state: AppState): string | null => {
  const currentLanguage = currentLanguageSelector(state);
  return state.globals[currentLanguage ?? '']?.onlineCatalog?.locale.current ?? null;
};

/**
 * Check if paremeter state with key and attribute is available
 *
 * @param state
 * @param currentCatalog
 * @param parameter
 * @param key
 * @param attribute
 * @returns {null}
 */
const isStateParameterAvailable = (
  state: AppState,
  currentCatalog: string | null,
  parameter: string,
  key: string,
  attribute: string,
) =>
  currentCatalog &&
  !!key &&
  state.productCatalogs?.[currentCatalog]?.[parameter]?.[key]?.[attribute];

export const productsByProductKeysSelector = (
  state: AppState,
  productKeys: ProductCategory['productKeys'],
): ProductProduct[] => {
  const currentCatalog = currentCatalogSelector(state);

  return (productKeys ?? [])
    .map((productKey) => productByKeySelector(state, productKey, currentCatalog))
    .filter(isNotEmpty);
};

export const productByKeySelector = (
  state: AppState,
  productKey: string,
  catalog?: string | null,
) => {
  const currentCatalog = catalog ?? currentCatalogSelector(state);

  return state.productCatalogs[currentCatalog ?? '']?.products?.[productKey ?? ''] ?? null;
};

export const productsByProductKeysCatalogSelector = (state: AppState, productKeys: string[]) => {
  return productKeys
    .map((productKey) =>
      productByKeySelector(state, productKey.split(':')[0], productKey.split(':')[1]),
    )
    .filter(isNotEmpty);
};

export const categoryProductsByProductKeySelector = (state: AppState, categoryId: string) => {
  const currentCatalog = currentCatalogSelector(state);
  const hasProperty = isStateParameterAvailable(
    state,
    currentCatalog,
    'categories',
    categoryId,
    'productKeys',
  );

  if (currentCatalog && hasProperty) {
    const { productKeys = [] } =
      state.productCatalogs[currentCatalog]?.categories?.[categoryId ?? ''] ?? {};
    return productKeys.map((product) => productByKeySelector(state, product));
  }

  return null;
};

export const totalCategoryProductsByProductKeySelector = (state: AppState, categoryId: string) => {
  const currentCatalog = currentCatalogSelector(state);
  const hasProperty = isStateParameterAvailable(
    state,
    currentCatalog,
    'categories',
    categoryId,
    'total',
  );

  if (currentCatalog && hasProperty) {
    return state.productCatalogs[currentCatalog]?.categories?.[categoryId ?? '']?.total;
  }

  return null;
};

export const isSectionLoadedBySectionIdSelector = (state: AppState, sectionId: string) => {
  const catalog = currentCatalogSelector(state);

  return state.productCatalogs[catalog ?? '']?.sections?.[sectionId ?? '']?.isFetching ?? null;
};

export const categoryByIdSelector = (state: AppState, categoryId: string) => {
  const catalog = currentCatalogSelector(state);

  return state.productCatalogs[catalog ?? '']?.categories?.[categoryId ?? ''] ?? null;
};

export const categoriesByIdsSelector = (state, categoryItems: CategoryItem[]) => {
  if (!categoryItems) return null;

  return categoryItems.map((category) => categoryByIdSelector(state, category.categoryCatalogId));
};
