import { createContext, useReducer } from 'react';
import reducer, { PageState } from './page-reducer';

// types
import type { Reducer } from 'redux';
import type { PageAction } from './actions';

export const PageContext = createContext<ReturnType<typeof useReducerWrapper>>({} as any);

function useReducerWrapper(reducer: Reducer<PageState, PageAction>, content) {
  return useReducer(reducer, { page: content as PageState });
}

export function PageContextProvider({
  children,
  content,
}: Readonly<React.PropsWithChildren<{ content: Content }>>) {
  const pageReducer = useReducerWrapper(reducer, content);
  return <PageContext.Provider value={pageReducer}>{children}</PageContext.Provider>;
}
