'use client';

import { createContext, useContext, useEffect, useMemo } from 'react';

const MetaDataContext = createContext({ lang: '' });

export function IsoLangProvider({ children, lang }) {
  useEffect(() => {
    document.querySelector('html')?.setAttribute('lang', lang);
  }, [lang]);

  const value = useMemo(() => ({ lang }), [lang]);

  return <MetaDataContext.Provider value={value}>{children}</MetaDataContext.Provider>;
}

export function useIsoLang() {
  const { lang } = useContext(MetaDataContext);
  if (!lang) {
    throw new Error('useIsoLang must be used within a IsoLangProvider');
  }

  return lang.split('-').join('_');
}

export function useIsoCountry() {
  return useIsoLang().split('_').pop() ?? '';
}
