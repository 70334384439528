import { useContext } from 'react';
import { PageContext } from './page-context';

// utils
import { isEmpty } from 'utils/is-empty';

export const useContent = <T extends {}, P extends {}>() => {
  const content = useContext(PageContext);

  return content[0]?.page as Omit<Content & T, 'page'> & { page: Page & P };
};

export const useDispatch = <T extends {}, P extends {}>() => {
  const content = useContext(PageContext);

  return content[1];
};

export const contentExists = (content: any): content is Content & { lastFetched: number } => {
  return !isEmpty(content);
};

export const hasContentAreas = (content: any): content is Content & { lastFetched: number } => {
  return !isEmpty(content?.contentAreas);
};
